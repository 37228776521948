import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyDownloadButton = lazy(
  () => import("./download-button-Dbm8DftZ.js").then((module) => ({
    default: module.DownloadButton
  }))
);
function DownloadButton({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyDownloadButton, { ...props });
}
const useDownloadButtonContent = () => {
  return useMemo(
    () => ({
      downloadButton: {
        component: DownloadButton
      }
    }),
    []
  );
};
export {
  useDownloadButtonContent
};
